import { CFlex, CMargin, Flex } from "../../../styles/CommonStyles";
import AddFilter from "./AddFilter";
import { useFilterContext } from "../../../core/contexts/filter";
import FilterItem from "./FilterItem";
import { DeleteAllFilters, SearchBtn, FiltersOn } from "../../../styles/layout/filter";
import Text from "../../../core/utils/Text";
import { useCallback, useEffect, useState } from "react";
import Tooltip from "../../common/utils/Tooltip";
import DownloadBtn from "../../common/buttons/DownloadBtn";
import { useQueryContext } from "../../../core/contexts/query";
import AddSort from "./sort/AddSort";
import { useWindowSize } from "../../../core/hooks/common/useWindowSize";
import { MOBILE_SIZE, TABLET_SIZE } from "../../../core/constants/common";
import SortItem from "./sort/SortItem";
import styled from "styled-components";
import { formatNumber } from "../../../core/utils/common";
import TotalAmount from "./TotalAmount";


const FilterView = ({
    options,
    query,
    onQuerySuccess,
    cache,
    extra = {},
    hasDownload,
    sortOptions,
    hasFilter = true,
    showTotal = false
}) => {
    const { width } = useWindowSize()
    const hasSort = width < TABLET_SIZE
    const { setToast } = useQueryContext()
    const {
        filters: _filters,
        clearAllFilters,
        pagination: _pagination,
        setPagination,
        filtersInAction,
        setFiltersInAction
    } = useFilterContext()

    const [csv, setCsv] = useState(false)

    const filters = _filters[cache]
    const filterList = Object.keys(filters)
    const pagination = _pagination[cache]
    // console.log(filterList, filters)

    useEffect(() => {
        if (Object.keys(filters).length) {
            setFiltersInAction(true)
        }
    }, [])

    const { data, refetch, isFetching: loading, isSuccess, isError } = query(filters, pagination, extra, csv)

    useEffect(() => {
        const keys = Object.keys(filters)
        if (!keys.length && filtersInAction) {
            const newState = { ...pagination }
            newState.page = 1
            setPagination(newState)
            setFiltersInAction(false)
        }
    }, [filters])

    useEffect(() => {
        if (csv) {
            refetch()
        }
    }, [csv])

    useEffect(() => {
        if ((isSuccess || isError) && csv) {
            setToast({
                isError: isError,
                show: true,
                message: isError ? 'add-download-fail' : 'add-download-success'
            })
            setCsv(false)
        }
    }, [isSuccess, isError, csv])

    useEffect(() => {
        if (!csv && !data?.data?.link) {
            onQuerySuccess({
                data: data, loading
            })
        }
    }, [data, loading])

    useEffect(() => {
        refetch()
    }, [pagination])

    const onSearchClicked = () => {
        setFiltersInAction(true)
        setPagination({ ...pagination, page: 1 })
        // refetch()
    }

    const onDownloadCsvClicked = () => {
        setCsv(true)
    }

    // console.log('filteredData', data)
    const calculateTotalShownAmount = useCallback(type => {
        let result
        if (type === 'BANK_TRANSACTIONS') {
            if (filterList?.length > 0) {
                result = data?.data?.reduce((acc, curr) => acc + curr.amount, 0) || '0'
            }
        }
        if (type === 'COIN_TRANSACTIONS') {
            if (filterList?.includes('coin')) result = data?.data?.reduce((acc, curr) => acc + curr.amount, 0) || '0'
        }
        if (type === 'CARTABLE') {
            if (filterList?.includes('params.currency')) {
                result = data?.data?.reduce((acc, curr) => acc + curr.params.volume, 0) || '0'
            }

        }

        // console.log(result)
        return result
    }
        , [showTotal])

    const initTotalShownAmount = calculateTotalShownAmount(showTotal.type)
    // console.log(initTotalShownAmount)
    const [totalShownAmount, setTotalShownAmount] = useState(initTotalShownAmount)
    useEffect(() => {
        if (loading) setTotalShownAmount('Calculating...')
        else setTotalShownAmount(calculateTotalShownAmount(showTotal.type))
    }, [data, loading, showTotal, calculateTotalShownAmount])

    return (
        <CFlex fw wrap align='flex-start'>
            <Flex fw justify={'space-between'} wrap>
                {showTotal && width < MOBILE_SIZE && totalShownAmount && <TotalAmount total={totalShownAmount} mb='1rem' />}
                <Flex>
                    {hasFilter ?
                        <AddFilter
                            options={options}
                            hasSort={hasSort}
                        />
                        : null}

                    {hasSort &&
                        <AddSort
                            options={sortOptions}
                        />
                    }
                    {filterList?.length ?
                        <DeleteAllFilters
                            onClick={clearAllFilters}
                        >
                            <Text tid={'delete-all-filters'} />
                        </DeleteAllFilters>
                        : null}
                    {
                        showTotal && width >= MOBILE_SIZE && totalShownAmount &&
                        <TotalAmount total={totalShownAmount} />
                    }
                </Flex>
                {hasDownload &&
                    <DownloadBtn
                        loading={loading && csv}
                        onClick={onDownloadCsvClicked}
                    />
                }
            </Flex>
            {(hasSort && pagination?.sort) &&
                <>
                    <CMargin margin='5px' />
                    <SortItem
                        sort={pagination.sort}
                        order={pagination.order}
                        options={sortOptions}
                    />
                </>
            }

            <CMargin margin='5px' />
            <Flex fw wrap justify={'flex-start'}>
                {filterList?.map(key => {

                    const value = filters[key]
                    return (
                        <FilterItem
                            key={key}
                            options={options}
                            prefix={key}
                            suffix={value}
                        />
                    )
                })}
                {filterList?.length ?
                    <SearchBtn
                        onClick={onSearchClicked}
                    >
                        <Text tid={'search'} />
                    </SearchBtn>
                    : null}
            </Flex>
            {filtersInAction &&
                <Tooltip
                    content={'filters-on'}
                >
                    <FiltersOn />
                </Tooltip>
            }
        </CFlex>
    )
}


export default FilterView
