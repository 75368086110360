import { useMemo, useState } from "react"
import CoinSelect from "../../../components/common/input/CoinSelect"
import ListLayout from "../../../components/layout/main/ListLayout"
import { useGetTransferSettings, useSetTransferSettings } from "../../../core/services/react-query/report/transfer"
import { Background, CFlex, CMargin, DText, Flex, Padding, PageCard } from "../../../styles/CommonStyles"
import Text from "../../../core/utils/Text"
import ActionButton from "../../../components/common/buttons/ActionButton"
import styled from 'styled-components'
import { deepCopy, formatNumber, stringToNumber } from "../../../core/utils/common"
import ModalLayout from "../../../components/layout/main/ModalLayout"
import Input from "../../../components/common/input/Input"
import { useQueryContext } from "../../../core/contexts/query"
import { SOCKET_URL } from "../../../core/constants/urls"

const initialEditableCoin = {
    feeFactor: '', feeMin: '',
    feeMax: '', minAmount: '',
    maxAmount: ''
}

const TransferSettings = () => {

    const [showError, setShowError] = useState(false)
    const { setToast } = useQueryContext()

    const [modal, setModal] = useState({ open: false, type: null })
    const openModal = (type) => setModal({ open: true, type })
    const closeModal = () => setModal({ open: false, type: null })

    const [editableCoin, setEditableCoin] = useState(initialEditableCoin)
    const [coin, setCoin] = useState(null)
    const { data: transferSettings } = useGetTransferSettings()

    const { mutate, isLoading } = useSetTransferSettings(closeModal)

    const coinAdded = useMemo(() => {
        setShowError(false)
        if (!!coin && transferSettings?.length) {
            const findCoin = transferSettings.find(x => x.currency === coin.id)
            if (!!findCoin) {
                setEditableCoin(findCoin)
                return findCoin
            }
        }
        setEditableCoin(initialEditableCoin)
        return null
    }, [transferSettings, coin])

    const onSubmit = () => {
        if (
            !!editableCoin.feeFactor
            && !!editableCoin.minAmount
            && !!editableCoin.maxAmount
        ) {
            const newCoins = deepCopy(transferSettings)

            const obj = {}
            obj.currency = coin.id
            obj.isActive = true
            obj.minAmount = stringToNumber(editableCoin.minAmount)
            obj.maxAmount = stringToNumber(editableCoin.maxAmount)
            obj.feeFactor = stringToNumber(editableCoin.feeFactor)
            obj.feeMin = stringToNumber(editableCoin.feeMin)
            obj.feeMax = stringToNumber(editableCoin.feeMax)

            console.log({ obj, editableCoin })

            if (modal.type === 'add') {
                newCoins.push(obj)
            } else {
                const findIndex = newCoins.findIndex(x => x.currency === obj.currency)
                if (findIndex > -1) {
                    newCoins[findIndex] = { ...obj }
                }
            }
            mutate(newCoins)
        } else {
            setShowError(true)
            setToast({ message: 'fill-inputs-error', show: true, isError: true })
        }
    }

    return (
        <ListLayout>
            <PageCard>
                <Padding padding={'30px 10px'}>
                    <CFlex style={{ gap: 20 }}>
                        <DText primary>
                            <Text tid={'transfer-setting-title'} />
                        </DText>
                        <CMargin margin={4} />
                        <CoinSelect
                            width={'300px'}
                            value={coin}
                            onChange={setCoin}
                        />

                        {!!coinAdded ?
                            <>
                                <CFlex align={'flex-start'}>
                                    <DText main fontSize={'s'}>
                                        <Text tid={'minAmountTransfer'} />
                                        <span>{` (${coinAdded.currency.toUpperCase()})`}</span>
                                    </DText>
                                    <TSItem>
                                        {formatNumber(coinAdded.minAmount, { type: coinAdded.currency })}
                                    </TSItem>
                                </CFlex>
                                <CFlex align={'flex-start'}>
                                    <DText main fontSize={'s'}>
                                        <Text tid={'maxAmountTransfer'} />
                                        <span>{` (${coinAdded.currency.toUpperCase()})`}</span>
                                    </DText>
                                    <TSItem>
                                        {formatNumber(coinAdded.maxAmount, { type: coinAdded.currency })}
                                    </TSItem>
                                </CFlex>
                                <CFlex align={'flex-start'}>
                                    <DText main fontSize={'s'}>
                                        <Text tid={'feeFactorTransfer'} />
                                    </DText>
                                    <TSItem>
                                        {formatNumber(coinAdded.feeFactor, { type: coinAdded.currency })}
                                    </TSItem>
                                </CFlex>
                                <CFlex align={'flex-start'}>
                                    <DText main fontSize={'s'}>
                                        <Text tid={'feeMinTransfer'} />
                                        <span>{` (${coinAdded.currency.toUpperCase()})`}</span>
                                    </DText>
                                    <TSItem>
                                        {formatNumber(coinAdded.feeMin, { type: coinAdded.currency })}
                                    </TSItem>
                                </CFlex>
                                <CFlex align={'flex-start'}>
                                    <DText main fontSize={'s'}>
                                        <Text tid={'feeMaxTransfer'} />
                                        <span>{` (${coinAdded.currency.toUpperCase()})`}</span>
                                    </DText>
                                    <TSItem>
                                        {formatNumber(coinAdded.feeMax, { type: coinAdded.currency })}
                                    </TSItem>
                                </CFlex>

                                <CMargin />
                                <ActionButton active width={'300px'} height={'40px'} onClick={() => openModal('edit')}>
                                    <Text tid={'edit-transfer-setting'} />
                                </ActionButton>
                            </>
                            :
                            <>
                                <DText main>
                                    <Text tid={'transfer-setting-not-available'} />
                                </DText>
                                <ActionButton active width={'300px'} height={'40px'} onClick={() => openModal('add')}>
                                    <Text tid={'add-transfer-setting'} />
                                </ActionButton>
                            </>
                        }
                    </CFlex>
                </Padding>

                <ModalLayout
                    isStatic
                    open={modal.open}
                    width={'480px'}
                >
                    <CFlex style={{ gap: 12 }}>
                        <DText primary>
                            <Text tid={`transfer-title-${modal.type}`} />
                        </DText>
                        <Flex>
                            <img
                                src={
                                    (coinAdded?.currency === 'irt' || coin?.id === 'irt') ?
                                        require('../../../assets/images/tooman.png') :
                                        SOCKET_URL + `assets/icon/${coinAdded?.currency || coin?.id}.png`
                                }
                                alt={' '}
                                width={'32px'}
                            />
                            <DText main style={{ margin: '0 8px' }}>
                                {coinAdded?.currency?.toUpperCase() || coin?.id?.toUpperCase()}
                            </DText>
                        </Flex>
                        <Background bg={'mainBg'}>
                            <Input
                                size={'small'}
                                value={editableCoin.minAmount}
                                onInputChange={(v) => setEditableCoin(state => ({ ...state, minAmount: v }))}
                                label={'minAmountTransfer'}
                                error={showError ? 'min-amount-required' : ''}
                                normal
                                number
                            />
                            <Input
                                size={'small'}
                                value={editableCoin.maxAmount}
                                onInputChange={(v) => setEditableCoin(state => ({ ...state, maxAmount: v }))}
                                label={'maxAmountTransfer'}
                                error={showError ? 'max-amount-required' : ''}
                                normal
                                number
                            />
                            <Input
                                size={'small'}
                                value={editableCoin.feeFactor}
                                onInputChange={(v) => setEditableCoin(state => ({ ...state, feeFactor: v }))}
                                label={'feeFactorTransfer'}
                                error={showError ? 'fee-factor-required' : ''}
                                normal
                                code
                            />
                            <Input
                                size={'small'}
                                value={editableCoin.feeMin}
                                onInputChange={(v) => setEditableCoin(state => ({ ...state, feeMin: v }))}
                                label={'feeMinTransfer'}
                                number
                            />
                            <Input
                                size={'small'}
                                value={editableCoin.feeMax}
                                onInputChange={(v) => setEditableCoin(state => ({ ...state, feeMax: v }))}
                                label={'feeMaxTransfer'}
                                number
                            />
                        </Background>

                        <Flex style={{ gap: 20 }} fw>
                            <ActionButton loading={isLoading} active height={'40px'} width={'45%'} onClick={onSubmit}>
                                <Text tid={'submit'} />
                            </ActionButton>
                            <ActionButton loading={isLoading} height={'40px'} width={'45%'} onClick={closeModal}>
                                <Text tid={'cancel'} />
                            </ActionButton>
                        </Flex>
                    </CFlex>
                </ModalLayout>
            </PageCard>
        </ListLayout>
    )
}

const TSItem = styled.div`
    color: ${props => props.theme.color};
    direction: ltr;
    border-radius: 6px;
    border: 1px solid ${props => props.theme.color}10;
    background-color: ${props => props.theme.secondaryBg};
    padding: 8px 16px;
    width: 300px;
    height: 40px;
`

export default TransferSettings